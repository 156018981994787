import React, { Fragment, useEffect, useState } from "react";
import Navbar2 from "../../components/Navbar2";
import PageTitle from "../../components/pagetitle";
import BlogList from "../../components/BlogList";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import axios from "axios";

const BlogPageFullwidth = () => {
  //   useEffect(() => {
  //     console.log(blog);
  //   }, [blog]);

  return (
    <Fragment>
      <Navbar2 hclass={"wpo-header-style-2"} topbarBlock={"topbar-block"} />

      <PageTitle pageTitle={"Latest News"} pagesub={"Blog"} />
      <BlogList blLeft={"d-none"} blRight={"col-lg-10 offset-lg-1"} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPageFullwidth;
