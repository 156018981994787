import * as React from "react";
import { useSelector } from "react-redux";
import { Button, Menu, MenuItem, IconButton } from "@material-ui/core/";
import useStyles from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import { Hidden } from "@material-ui/core";
const MyAccount = ({ logout }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = useSelector((store) => store);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setDisplayName = () => {
    let name;
    if (user.user.displayName) name = user.user.displayName;
    else if (user.user.name) name = user.user.name;
    else if (user.user.email) name = user.user.email.toLowerCase();
    return name;
  };

  const goToAdmin = () => {
    setAnchorEl(null);
    history.push("/admin");
  };

  if (user.user.email) {
    return (
      <>
        <Hidden smDown>
          <Button
            id="basic-button"
            variant="outlined"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.button}
            size="large"
            style={{ color: "#fff" }}
          >
            {`Hello ${setDisplayName()}`}
          </Button>
        </Hidden>
        <Hidden smUp>
          <IconButton
            id="basic-button"
            variant="outlined"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.button}
            size="large"
            style={{ color: "#fff" }}
          >
            <PersonIcon />
          </IconButton>
        </Hidden>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ zIndex: 999, top: 50 }}
        >
          {user.user.email === process.env.REACT_APP_ADMIN && (
            <MenuItem onClick={() => goToAdmin()}>Admin</MenuItem>
          )}
          {/* <MenuItem onClick={handleClose}>Change password</MenuItem> */}
          <MenuItem onClick={logout}>Sign out</MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <Button
      id="basic-button"
      variant="outlined"
      aria-haspopup="true"
      onClick={() => history.push("/login")}
      className={classes.button}
      size="large"
      style={{ color: "#fff" }}
    >
      Sign in
    </Button>
  );
};

export default MyAccount;
