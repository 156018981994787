import React from "react";
import arianaImage from "../../images/ariana_wedding_planner.jpg";

const About = (props) => {
  return (
    <section className={`wpo-about-section section-padding ${props.pt}`}>
      <div className="container">
        <div className="wpo-about-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                <img src={arianaImage} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-content">
                <div className="about-title">
                  <h2>Wedding & Event Planner</h2>
                </div>
                <div className="wpo-about-content-inner">
                  <p>
                    Buna dragii mei, eu sunt Ariana si sunt bucuroasa sa va ajut
                    in crearea celui mai frumos eveniment din viata voastra. Din
                    pasiunea pentru frumos si perfectiune am creat conceptul
                    Arisa, un concept care promite profesionalism si bun gust.
                    Nu trebuie sa te mai stresezi doar gandindu-te la
                    evenimentul tau, lasa totul pe seama noastra si vom face ca
                    evenimentul tau sa fie memorabil, unic si superb.{" "}
                  </p>
                  <span>Cu ce ne ocupam?</span>
                  <h6>
                    Organizam si coordonam orice tip de eveniment, cum ar fi:
                  </h6>
                  <h6>☆ Nunta</h6>
                  <h6>☆ Botez</h6>
                  <h6>☆ Cununie civila</h6>
                  <h6>☆ Aniversare</h6>
                  <h6>☆ Petrecere Corporate</h6>
                  <h6>☆ Petrecere Privata</h6>
                  <h6>☆ Baby Shower/Gender Reveal</h6>
                  <h6>☆ Petrecerea Burlacitelor</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
