import axios from "axios";
import { withRouter } from "react-router-dom";

import React, { Component } from "react";
import { ref, uploadString, getDownloadURL } from "@firebase/storage";
import { storage } from "../../main-component/Firebase";
import { toast } from "react-toastify";
import { Button } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";

class AdminCreateProductForm extends Component {
  state = {
    title: "",
    categories: "",
    proImg: "",
    description: "",
    base64URL: "",
    error: {},
    price: 0,
    oldPrice: 0,
    productsCount: 0,
    test: "",
  };

  inputRef = React.createRef();
  editorRef = React.createRef(null);

  getProductsCount = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE}/product/get`,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    this.state.productsCount = result.data[result.data.length - 1].id;
  };

  componentDidMount = async () => {
    this.getProductsCount();
  };

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  changeDescription = (value) => {
    const error = this.state.error;
    error.description = "";

    this.setState({
      description: value,
      error,
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  uploadImage = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";
    let { file } = this.state;
    file = e.target.files[0];
    this.setState({
      ...this.state,
      test: e.target.files[0],
    });
    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        this.setState({
          base64URL: result,
          error,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.changeHandler(e);
  };

  createProduct = async () => {
    const imageRef = ref(
      storage,
      `product/${parseInt(this.state.productsCount) + 1}`
    );
    await uploadString(imageRef, this.state.base64URL, "data_url").then(
      async (snapshot) => {
        this.state.proImg = await getDownloadURL(imageRef);
        this.postRequest();
      }
    );
  };

  postRequest = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE}/product/post?id=${
          parseInt(this.state.productsCount) + 1
        }`,
        {
          id: parseInt(this.state.productsCount) + 1,
          title: this.state.title,
          categories: this.state.categories,
          description: this.state.description,
          price: this.state.price,
          oldPrice: this.state.oldPrice,
          proImg: this.state.proImg,
        },
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response) => {
        toast.success("Product created sucessfully!");
        this.resetStateValues();
        this.getProductsCount();
        this.props.history.push(
          `/product-single/${parseInt(this.state.productsCount) + 1}`
        );
      })
      .catch((error) => {
        console.log(error);
        toast.warning("Product NOT created");
        this.resetStateValues();
      });
  };

  resetStateValues = () => {
    this.setState({
      title: "",
      categories: "",
      proImg: "",
      description: "",
      base64URL: "",
      error: {},
      price: 0,
      oldPrice: 0,
    });
    this.inputRef.current.value = null;
  };

  subimtHandler = (e) => {
    e.preventDefault();
    const { error, categories, title, price, description, proImg } = this.state;
    if (!categories) {
      error.categories = "Please select a category";
    }
    if (!title) {
      error.title = "Please enter product name";
    }
    if (!price) {
      error.price = "Please enter product price";
    }
    if (!description) {
      error.description = "Please enter product description";
    }
    if (!proImg) {
      error.proImg = "Please enter product image";
    }
    if (error) {
      this.setState({
        error,
      });
      console.log(error);
    }
    if (
      error.categories === "" &&
      error.title === "" &&
      error.price === "" &&
      error.description === "" &&
      error.proImg === ""
    ) {
      this.createProduct();
    }
  };

  render() {
    const { title, price, oldPrice, categories, description, error } =
      this.state;

    return (
      <form onSubmit={this.subimtHandler} className="form">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <label>Title</label>
              <input
                value={title}
                onChange={this.changeHandler}
                type="text"
                name="title"
                placeholder="Name"
              />
              <p>{error.title ? error.title : ""}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <label>Price</label>
              <input
                value={price}
                onChange={this.changeHandler}
                type="number"
                name="price"
                placeholder="Price"
              />
              <p>{error.price ? error.price : ""}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <label>Old price</label>
              <input
                value={oldPrice}
                onChange={this.changeHandler}
                type="number"
                name="oldPrice"
                placeholder="Old Price"
              />
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                onChange={this.uploadImage}
                type="file"
                ref={this.inputRef}
                name="proImg"
                placeholder="Cover image"
                accept="image/png, image/gif, image/jpeg"
              />
              <p>{error.proImg ? error.proImg : ""}</p>
            </div>
          </div> */}
          <div className="col-lg-6 col-md-6 col-12">
            <div>
              <label>Category</label>

              <select
                name="categories"
                className="form-control"
                value={categories}
                onChange={this.changeHandler}
              >
                <option>Categories</option>
                <option>Guest book</option>
                <option>Wedding planner</option>
                <option>Packages</option>
              </select>
              <p>{error.categories ? error.categories : ""}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-field">
              {/* <textarea
                name="description"
                placeholder="Description"
                value={description}
                onChange={this.changeHandler}
              ></textarea> */}
              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                onInit={(evt, editor) => (this.editorRef.current = editor)}
                onEditorChange={(value) => this.changeDescription(value)}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
              <p>{error.description ? error.description : ""}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <Button
                variant="contained"
                onClick={() => this.inputRef.current.click()}
              >
                Upload file
              </Button>
              <input
                onChange={this.uploadImage}
                type="file"
                ref={this.inputRef}
                name="proImg"
                placeholder="Cover image"
                accept="image/png, image/gif, image/jpeg"
                hidden
              />
              <p>{error.proImg ? error.proImg : ""}</p>
              {this.state.test && (
                <div
                  style={{
                    width: 300,
                    maxHeight: 300,
                    borderRadius: 8,
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{ width: "auto" }}
                    src={URL.createObjectURL(this.state.test)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-submit">
              <button type="submit" className="theme-btn-s4 mt-4">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
export default withRouter(AdminCreateProductForm);
