import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";

import Packages from "../../api/packages";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const PricingSection = (props) => {
  var settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className={`wpo-pricing-section section-padding ${props.ptClass}`}>
      <div className="container">
        <SectionTitle topTitle={"Pricing"} MainTitle={"Wedding Packages"} />
        <div className="wpo-pricing-wrap">
          <div className="row">
            <div className="wpo-testimonial-wrap">
              <div className="testimonial-slider owl-carousel">
                <Slider {...settings}>
                  {Packages.map((packages, pitem) => (
                    <div className="col col-lg-4 col-md-6 col-12" key={pitem}>
                      <div className="wpo-pricing-item">
                        <div className="wpo-pricing-img">
                          <img src={packages.prImg} alt="" />
                        </div>
                        <div className="wpo-pricing-top">
                          <div className="wpo-pricing-text">
                            <h3>{packages.package}</h3>
                            {/* <h2>
                              ${pricing.price}
                              <span>/{pricing.subs}</span>
                            </h2> */}
                          </div>
                        </div>
                        <div className="wpo-pricing-bottom">
                          <div className="wpo-pricing-bottom-text">
                            <ul>
                              <li>{packages.label}</li>
                              {/* <li>{pricing.l2}</li>
                              <li>{pricing.l3}</li>
                              <li>{pricing.l4}</li>
                              <li>{pricing.l5}</li> */}
                            </ul>
                            <Link onClick={ClickHandler} to={packages.link}>
                              Alege pachetul
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
