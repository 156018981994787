import simg1 from "../images/service/img-1.jpg";
import simg2 from "../images/service/img-2.jpg";
import simg3 from "../images/service/img-3.jpg";
import simg4 from "../images/service/img-4.jpg";

import sinnermg1 from "../images/service-single/2.jpg";
import sinnermg2 from "../images/service-single/3.jpg";

import singleImg1 from "../images/service-single/1.jpg";
import singleImg2 from "../images/service-single/4.jpg";
import singleImg3 from "../images/service-single/5.jpg";
import singleImg4 from "../images/service-single/6.jpg";

const Services = [
  {
    id: "1",
    fIcon1: "flaticon-gallery",
    title: "Event Planning",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg1,
    ssImg: singleImg1,
    sinnerImg1: sinnermg1,
    sinnerImg2: sinnermg2,
  },
  {
    id: "2",
    fIcon1: "flaticon-serving-dish",
    title: "Cake Design",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg2,
    ssImg: singleImg2,
    sinnerImg1: sinnermg1,
    sinnerImg2: sinnermg2,
  },
  {
    id: "3",
    fIcon1: "flaticon-edit",
    title: "Floral Design",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg3,
    ssImg: singleImg3,
    sinnerImg1: sinnermg1,
    sinnerImg2: sinnermg2,
  },
  {
    id: "4",
    fIcon1: "flaticon-wedding",
    title: "Photography",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg4,
    ssImg: singleImg4,
    sinnerImg1: sinnermg1,
    sinnerImg2: sinnermg2,
  },
];
export default Services;
