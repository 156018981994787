import React, { Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import FontAwesome from "../../components/UiStyle/FontAwesome";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import { totalPrice } from "../../utils";
// images
import visa from "../../images/icon/visa.png";
import mastercard from "../../images/icon/mastercard.png";
import skrill from "../../images/icon/skrill.png";
import paypal from "../../images/icon/paypal.png";
import CheckWrap from "../CheckWrap";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { emptyCart } from "../../store/actions/action";
import "./style.scss";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";

const CheckoutSection = ({ cartList, user }) => {
  // states
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  let history = useHistory();

  const cardElemetOptions = {
    style: {
      base: {
        iconColor: "rgb(240, 57, 122)",
        color: "#000",
        fontSize: "16px",
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#CFD7DF",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#303238",
        },
      },
    },
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const [value, setValue] = useState({
    email: user.user.email,
    name: user.user.displayName,
  });

  const [tabs, setExpanded] = React.useState({
    cupon: false,
    // billing_adress: false,
    payment: true,
  });
  const [forms, setForms] = React.useState({
    cupon_key: "",
    fname: "",
    lname: "",
    country: "",
    dristrict: "",
    address: "",
    post_code: "",
    email: "",
    phone: "",
    note: "",

    payment_method: "cash",
    card_type: "",

    fname2: "",
    lname2: "",
    country2: "",
    dristrict2: "",
    address2: "",
    post_code2: "",
    email2: "",
    phone2: "",

    card_holder: "",
    card_number: "",
    cvv: "",
    expire_date: "",
  });

  const [dif_ship, setDif_ship] = React.useState(false);

  // tabs handler
  function faqHandler(name) {
    setExpanded({
      cupon: false,
      billing_adress: false,
      payment: true,
      [name]: !tabs[name],
    });
  }

  // forms handler
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleCardPayment();

    // const { id } = card;

    // console.log(card);

    //API
    //    history.push('/order_received');
    // }  else {
    //     toast.info('user not existed!');
    //     alert('user not existed! credential is : user@*****.com | vendor@*****.com | admin@*****.com');
    // }
  };

  const handleCardPayment = async () => {
    const cardNumberElement = elements?.getElement(CardNumberElement);
    if (cardNumberElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: value,
      });

      //`${process.env.REACT_APP_API_BASE}/customer/payments`
      const { id } = paymentMethod;
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE}/customer/payments`,
          {
            // quantity: 1,
            id: id,
            billing_details: value,
            cart_list: cartList,
          },
          {
            headers: {
              Authorization: `Basic ${process.env["REACT_APP_TOKEN"]}`,
            },
          }
        )
        .then((response) => {
          toast.success("Thank you for your order");
          dispatch(emptyCart());
          history.push("/shop");
        })
        .catch((error) => {
          console.log(error);
          toast.warning("Order NOT received");
        });
    } else {
      console.log("empty");
    }
  };

  return (
    <Fragment>
      <Grid className="checkoutWrapper section-padding">
        <Grid className="container" container spacing={3}>
          <Grid item md={6} xs={12}>
            <div>
              <Grid className="cuponWrap checkoutCard">
                {/* <Button
                  className="collapseBtn"
                  fullWidth
                  onClick={() => faqHandler("cupon")}
                >
                  Have a coupon ? Click here to enter your code.
                  <FontAwesome name={tabs.cupon ? "minus" : "plus"} />
                </Button> */}
                <Collapse in={tabs.cupon} timeout="auto" unmountOnExit>
                  <Grid className="chCardBody">
                    <p>If you have coupon code,please apply it</p>
                    <form className="cuponForm">
                      <TextField
                        fullWidth
                        type="text"
                        className="formInput radiusNone"
                        value={forms.cupon_key}
                        name="cupon_key"
                        onChange={(e) => changeHandler(e)}
                      />
                      <Button className="cBtn cBtnBlack">Apply</Button>
                    </form>
                  </Grid>
                </Collapse>
              </Grid>
              {/* <Grid className="cuponWrap checkoutCard">
                                <Button className="collapseBtn" fullWidth onClick={() => faqHandler('billing_adress')}>
                                    Billing Address
                                    <FontAwesome name={tabs.billing_adress ? 'minus' : 'plus'}/>
                                </Button>
                                <Collapse in={tabs.billing_adress} timeout="auto" unmountOnExit>
                                    <Grid className="chCardBody">
                                        <form className="cuponForm">
                                            <Grid container spacing={3}>
                                                <Grid item sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="First Name"
                                                        name="fname"
                                                        value={forms.fname}
                                                        onChange={(e) => changeHandler(e)}
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="formInput radiusNone"
                                                    />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Last Name"
                                                        name="lname"
                                                        value={forms.lname}
                                                        onChange={(e) => changeHandler(e)}
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="formInput radiusNone"
                                                    />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <InputLabel id="demo-simple-select-filled-label">Age</InputLabel>
                                                    <FormControl className="formSelect" fullWidth variant="filled">
                                                        <Select
                                                            labelId="demo-simple-select-filled-label"
                                                            id="demo-simple-select-filled"
                                                            value={forms.country}
                                                            name="country"
                                                            onChange={(e) => changeHandler(e)}
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Dristrict"
                                                        name="dristrict"
                                                        value={forms.dristrict}
                                                        onChange={(e) => changeHandler(e)}
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="formInput radiusNone"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        rows="3"
                                                        label="Address"
                                                        name="address"
                                                        value={forms.address}
                                                        onChange={(e) => changeHandler(e)}
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="formInput radiusNone"
                                                    />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Post Code"
                                                        name="post_code"
                                                        value={forms.post_code}
                                                        onChange={(e) => changeHandler(e)}
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="formInput radiusNone"
                                                    />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Email Adress"
                                                        name="email"
                                                        value={forms.email}
                                                        onChange={(e) => changeHandler(e)}
                                                        type="email"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="formInput radiusNone"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Phone No"
                                                        name="phone"
                                                        value={forms.phone}
                                                        onChange={(e) => changeHandler(e)}
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="formInput radiusNone"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        className="checkBox"
                                                        control={
                                                            <Checkbox
                                                                checked={dif_ship}
                                                                onChange={() => setDif_ship(!dif_ship)}
                                                                value={dif_ship}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Ship to a different address?"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Collapse in={dif_ship} timeout="auto" unmountOnExit>
                                                        <Grid container spacing={3}>
                                                            <Grid item sm={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="First Name"
                                                                    name="fname2"
                                                                    value={forms.fname2}
                                                                    onChange={(e) => changeHandler(e)}
                                                                    type="text"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    className="formInput radiusNone"
                                                                />
                                                            </Grid>
                                                            <Grid item sm={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Last Name"
                                                                    name="lname2"
                                                                    value={forms.lname2}
                                                                    onChange={(e) => changeHandler(e)}
                                                                    type="text"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    className="formInput radiusNone"
                                                                />
                                                            </Grid>
                                                            <Grid item sm={6} xs={12}>
                                                                <InputLabel
                                                                    id="demo-simple-select-filled-label">Age</InputLabel>
                                                                <FormControl className="formSelect" fullWidth
                                                                            variant="filled">
                                                                    <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        value={forms.country2}
                                                                        name="country2"
                                                                        onChange={(e) => changeHandler(e)}
                                                                    >
                                                                        <MenuItem value="">
                                                                            <em>None</em>
                                                                        </MenuItem>
                                                                        <MenuItem value={10}>Ten</MenuItem>
                                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item sm={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Dristrict"
                                                                    name="dristrict2"
                                                                    value={forms.dristrict2}
                                                                    onChange={(e) => changeHandler(e)}
                                                                    type="text"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    className="formInput radiusNone"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    multiline
                                                                    rows="3"
                                                                    label="Address"
                                                                    name="address2"
                                                                    value={forms.address2}
                                                                    onChange={(e) => changeHandler(e)}
                                                                    type="text"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    className="formInput radiusNone"
                                                                />
                                                            </Grid>
                                                            <Grid item sm={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Post Code"
                                                                    name="post_code2"
                                                                    value={forms.post_code2}
                                                                    onChange={(e) => changeHandler(e)}
                                                                    type="text"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    className="formInput radiusNone"
                                                                />
                                                            </Grid>
                                                            <Grid item sm={6} xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Email Adress"
                                                                    name="email2"
                                                                    value={forms.email2}
                                                                    onChange={(e) => changeHandler(e)}
                                                                    type="email"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    className="formInput radiusNone"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Phone No"
                                                                    name="phone2"
                                                                    value={forms.phone2}
                                                                    onChange={(e) => changeHandler(e)}
                                                                    type="text"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    className="formInput radiusNone"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Collapse>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        label="Order Notes"
                                                        placeholder="Note about your order"
                                                        name="note"
                                                        value={forms.note}
                                                        onChange={(e) => changeHandler(e)}
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        className="formInput radiusNone note"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                </Collapse>
                            </Grid> */}
              <Grid className="cuponWrap checkoutCard">
                <Button
                  className="collapseBtn"
                  fullWidth
                  onClick={() => faqHandler("payment")}
                >
                  Payment Method
                  <FontAwesome name={tabs.payment ? "minus" : "plus"} />
                </Button>
                <Grid className="chCardBody">
                  <Collapse in={tabs.payment} timeout="auto">
                    {/* <RadioGroup
                      className="paymentMethod"
                      aria-label="Payment Method"
                      name="payment_method"
                      value={forms.payment_method}
                      onChange={(e) => changeHandler(e)}
                    > */}
                    {/* <FormControlLabel
                        value="cash"
                        control={<Radio color="primary" />}
                        label="Payment By Card "
                      /> */}
                    {/* <FormControlLabel
                        value="card"
                        control={<Radio color="primary" />}
                        label="Cash On delivery"
                      /> */}
                    {/* </RadioGroup> */}
                    {/* <Collapse
                      in={forms.payment_method === "cash"}
                      timeout="auto"
                    > */}
                    {/* <Grid className="cardType">
                      {cardType.map((item, i) => (
                        <Grid
                          key={i}
                          className={`cardItem ${
                            forms.card_type === item.title ? "active" : null
                          }`}
                          onClick={() =>
                            setForms({ ...forms, card_type: item.title })
                          }
                        >
                          <img src={item.img} alt={item.title} />
                        </Grid>
                      ))}
                    </Grid> */}
                    <Grid>
                      <Grid className="cardbp mt-20">
                        <Grid>
                          <form
                            onSubmit={handleSubmit}
                            className="check-form-area"
                          >
                            <Grid container spacing={3}>
                              <Grid item sm={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Card holder Name"
                                  name="card_holder"
                                  // value={value.card_holder}
                                  onChange={(e) => changeHandler(e)}
                                  type="text"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  className="formInput radiusNone"
                                />
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                {/* <TextField
                                                                        fullWidth
                                                                        label="Card Number"
                                                                        // name="card_number"
                                                                        // value={value.card_number}
                                                                        // onChange={(e) => changeHandler(e)}
                                                                        // type="number"
                                                                        // InputLabelProps={{
                                                                        //     shrink: true,
                                                                        // }}
                                                                        className="formInput radiusNone"
                                                                    /> */}

                                {/* <CardInputWrapper > */}
                                <label>
                                  <span>Card number</span>
                                </label>
                                <CardNumberElement
                                  options={cardElemetOptions}
                                />
                                {/* </CardInputWrapper> */}
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                {/* <TextField
                                                                        fullWidth
                                                                        label="CVV"
                                                                        name="cvv"
                                                                        // value={value.cvv}
                                                                        onChange={(e) => changeHandler(e)}
                                                                        type="text"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        className="formInput radiusNone"
                                                                    /> */}
                                {/* <CardInputWrapper> */}
                                <label>
                                  <span>Card Verification Code</span>
                                </label>
                                <CardCvcElement options={cardElemetOptions} />
                                {/* </CardInputWrapper> */}
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                {/* <TextField
                                                                        fullWidth
                                                                        label="Expire Date"
                                                                        name="expire_date"
                                                                        // value={value.expire_date}
                                                                        onChange={(e) => changeHandler(e)}
                                                                        type="date"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        className="formInput radiusNone"
                                                                    /> */}
                                {/* <CardInputWrapper> */}
                                <label>
                                  <span>Card Expiry</span>
                                </label>
                                <CardExpiryElement
                                  options={cardElemetOptions}
                                />
                                {/* <CardExpiryElement options={{
                                                                            style: {
                                                                                base: inputStyle,
                                                                            },
                                                                        }} /> */}
                                {/* </CardInputWrapper> */}
                              </Grid>
                              <Grid item xs={12}>
                                <Grid className="formFooter mt-20">
                                  <Button
                                    fullWidth
                                    className="cBtn cBtnLarge cBtnTheme mt-20 ml-15"
                                    type="submit"
                                  >
                                    Proceed to Checkout
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                  <Collapse in={forms.payment_method === "card"} timeout="auto">
                    <Grid className="cardType">
                      <Link
                        to="/order_received"
                        className="cBtn cBtnLarge cBtnTheme mt-20 ml-15"
                        type="submit"
                      >
                        Proceed to Checkout
                      </Link>
                    </Grid>
                  </Collapse>
                  {/* </Collapse> */}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid className="cartStatus">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid className="cartTotals">
                    <h4>Cart Total</h4>
                    <Table>
                      <TableBody>
                        {cartList.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>
                              {item.title} {item.price} RON x {item.qty}
                            </TableCell>
                            <TableCell align="right">
                              {item.qty * item.price} RON
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow className="totalProduct">
                          <TableCell>Total product</TableCell>
                          <TableCell align="right">{cartList.length}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Sub Price</TableCell>
                          <TableCell align="right">
                            {totalPrice(cartList)} RON
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Total Price</TableCell>
                          <TableCell align="right">
                            {totalPrice(cartList)} RON
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CheckoutSection;
