import React from "react";
import ContactForm from "../ContactFrom";

const Contactpage = () => {
  const handlemail = () => {
    const mailto =
      "mailto:office@arisaevents.com?subject=Arisa Events&body=Body content";
    window.location.href = mailto;
  };

  const handletel = () => {
    const telto = "tel:+40748958596";
    window.location.href = telto;
  };
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              <div className="row">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-maps-and-flags"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Address</h2>
                      <p>Sibiu, Romania</p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-email"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Email Us</h2>
                      <p onClick={handlemail} className="custom-email-icon">
                        <i></i>office@arisaevents.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-phone-call"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Call Now</h2>
                      <p onClick={handletel} className="custom-tel-icon">
                        <i></i>+40 748 958 596
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpo-contact-title">
              <h2>Have Any Question?</h2>
              {/* <p>
                It is a long established fact that a reader will be distracted
                content of a page when looking.
              </p> */}
            </div>
            <div
              className="wpo-contact-form-area"
              style={{ marginBottom: "-100px" }}
            >
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Sibiu+(Arisa%20Events)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
      </section>
    </section>
  );
};

export default Contactpage;
