import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle";
import blogs from "../../api/blogs";
import { storage } from "../../main-component/Firebase";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

const BlogHomePage = (props) => {
  const { user } = props.user;
  let history = useHistory();
  const [blogs, setBlogs] = React.useState();
  const [rerender, setRerender] = React.useState(false);
  const token = process.env.REACT_APP_TOKEN;

  const fetchData = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE}/blog/get`,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    setBlogs(result.data);
  };
  React.useEffect(() => {
    if (rerender) {
      fetchData();
      setRerender(false);
    }
  }, [rerender]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const sliceText = (text) => {
    const sliceText = text.slice(0, 270).concat("...");
    return sliceText;
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className={`wpo-blog-section section-padding ${props.blogClass}`}>
      <div className="container">
        <SectionTitle topTitle={"News"} MainTitle={"From Our Blog"} />
        <div className="wpo-blog-items">
          <div className="row">
            {blogs?.slice(0, 3).map((blog, bitem) => (
              <div className="col col-lg-4 col-md-6 col-12" key={bitem}>
                <div className="wpo-blog-item">
                  <div className="wpo-blog-img" style={{ maxHeight: 250 }}>
                    <img
                      src={blog.coverImage}
                      alt={blog.coverImage}
                      style={{ height: "auto" }}
                    />
                  </div>
                  <div className="wpo-blog-content">
                    <h2>
                      <Link
                        onClick={ClickHandler}
                        to={`/blog-single-fullwidth/${blog.id}`}
                      >
                        {blog.title}
                      </Link>
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{ __html: sliceText(blog.text) }}
                      style={{ marginBottom: 10 }}
                    ></div>
                    <Link
                      onClick={ClickHandler}
                      to={`/blog-single-fullwidth/${blog.id}`}
                      className="read-more"
                    >
                      READ MORE...
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(BlogHomePage);
