import React from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/style.scss";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51KmBfJKxWJCOg0dcagul90MRj2OXef8UzlVCQFaLhszK2usuepHZUlRLtbQHIsE2dwyaaFjiWBcQyieutF8lFe1w00MTDO4p9X"
);

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <div className="App" id="scrool">
        <AllRoute />
        <ToastContainer />
      </div>
    </Elements>
  );
};

export default App;
