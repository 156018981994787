import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { storage } from "../../main-component/Firebase";
import { toast } from "react-toastify";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";

function AdminUpdateProductForm(props) {
  const { user } = props.user;
  let history = useHistory();
  const { id } = useParams();
  const [error, setError] = React.useState();
  const [submit, setSubmitting] = React.useState(false);
  const [productDetails, setProductDetails] = React.useState();
  const [image, setImage] = React.useState();

  const inputRef = React.createRef();
  const editorRef = React.useRef(null);

  const fetchBlogData = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE}/product/id/get?id=${id}`,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    setProductDetails(result.data);
  };

  React.useEffect(() => {
    fetchBlogData();
  }, []);

  const changeHandler = (e) => {
    e.preventDefault();
    setProductDetails({
      ...productDetails,
      [e.target.name]: e.target.value,
    });
  };

  const updateDescription = (value) => {
    setProductDetails({
      ...productDetails,
      description: value,
    });
  };

  const subimtHandler = (e) => {
    setError();
    e.preventDefault();
    checkForErrors();
    setSubmitting(true);
  };

  const checkForErrors = () => {
    if (productDetails.description.length === 0) {
      setError({ ...error, description: "Please add description" });
    }
    if (productDetails.title.length === 0) {
      setError({ ...error, title: "Please add title" });
    }
    if (productDetails.price.length === 0) {
      setError({ ...error, price: "Please add price" });
    }
  };

  const updateProduct = async () => {
    if (productDetails.base64URL) {
      const imageRef = ref(storage, `product/${productDetails.id}`);
      await uploadString(imageRef, productDetails.base64URL, "data_url").then(
        async (snapshot) => {
          productDetails.proImg = await getDownloadURL(imageRef);
          postRequest();
        }
      );
    } else postRequest();
  };

  React.useEffect(() => {
    if (!error && submit) {
      updateProduct();
    }
  }, [submit, error]);

  const postRequest = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE}/product/post?id=${productDetails.id}`,
        {
          id: productDetails.id,
          title: productDetails.title,
          categories: productDetails.categories,
          description: productDetails.description,
          price: productDetails.price,
          oldPrice: productDetails.oldPrice,
          proImg: productDetails.proImg,
        },
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response) => {
        toast.success("Product update sucessfully!");
        history.push(`/product-single/${productDetails.id}`);
        setSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        toast.warning("Product NOT update");
      });
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setProductDetails({ ...productDetails, base64URL: result });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <>
      {user.email === process.env.REACT_APP_ADMIN ? (
        <section className="wpo-contact-pg-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-lg-10 offset-lg-1">
                <div className="wpo-contact-title">
                  <h2>Edit Product</h2>
                </div>
                <div className="wpo-contact-form-area">
                  <form onSubmit={subimtHandler} className="form">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                          <input
                            value={productDetails?.title || " "}
                            onChange={changeHandler}
                            type="text"
                            name="title"
                            placeholder="Name"
                          />
                          <p>{error?.title ? error.title : ""}</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                          <input
                            value={productDetails?.price || " "}
                            onChange={changeHandler}
                            type="number"
                            name="price"
                            placeholder="Price"
                          />
                          <p>{error?.price ? error.price : ""}</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                          <input
                            value={productDetails?.oldPrice || " "}
                            onChange={changeHandler}
                            type="number"
                            name="oldPrice"
                            placeholder="Old Price"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-12">
                        <div>
                          <select
                            name="categories"
                            className="form-control"
                            value={productDetails?.categories || " "}
                            onChange={changeHandler}
                          >
                            <option>Categories</option>
                            <option>Guest book</option>
                            <option>Wedding planner</option>
                            <option>Packages</option>
                          </select>
                          <p>{error?.categories ? error.categories : ""}</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                          <img
                            src={
                              productDetails?.base64URL
                                ? productDetails?.base64URL
                                : productDetails?.proImg
                            }
                            alt=""
                          />
                          <input
                            onChange={uploadImage}
                            type="file"
                            name="proImg"
                            placeholder="Cover image"
                            accept="image/png, image/gif, image/jpeg"
                          />
                          <p>{error?.proImg ? error.proImg : ""}</p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-field">
                          {/* <textarea
                            name="description"
                            placeholder="Description"
                            value={productDetails?.description || " "}
                            onChange={changeHandler}
                          ></textarea> */}
                          <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue={productDetails?.description || " "}
                            onEditorChange={(value) => updateDescription(value)}
                            init={{
                              height: 500,
                              menubar: false,
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                              ],
                              toolbar:
                                "undo redo | blocks | " +
                                "bold italic forecolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | " +
                                "removeformat | help",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                          />
                          <p>{error?.description ? error.description : ""}</p>
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                          <Button
                            variant="contained"
                            onClick={() => inputRef.current.click()}
                          >
                            Upload file
                          </Button>
                          <input
                            onChange={uploadImage}
                            type="file"
                            ref={inputRef}
                            name="imageName"
                            placeholder="Cover image"
                            accept="image/png, image/gif, image/jpeg"
                            hidden
                          />
                          <p>{error.imageName ? error.imageName : ""}</p>
                          {image && (
                            <div
                              style={{
                                width: 300,
                                maxHeight: 300,
                                borderRadius: 8,
                                overflow: "hidden",
                              }}
                            >
                              <img
                                style={{ width: "auto" }}
                                src={URL.createObjectURL(image)}
                              />
                            </div>
                          )}
                        </div>
                      </div> */}
                      <div className="col-lg-12">
                        <div className="form-submit">
                          <button type="submit" className="theme-btn-s4 mt-4">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <h1>Admin Only</h1>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(AdminUpdateProductForm);
