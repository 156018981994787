import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const BlogSingle = (props) => {
  const { id } = useParams();
  const [blogDetails, setBlogDetails] = React.useState();
  const [blogComments, setBlogComments] = React.useState();
  const [highlightedText, setHighlightedText] = React.useState();
  const [splitText1, setSplitText1] = React.useState();
  const [splitText2, setSplitText2] = React.useState();
  const [userComment, setUserComment] = React.useState();
  const { user } = props.user;
  const token = process.env.REACT_APP_TOKEN;

  const fetchBlogData = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE}/blog/id/get?id=${id}`,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    setBlogDetails(result.data);
    filterText(result.data.text);
  };

  const fetchCommentsData = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE}/comments/get?id=${id}`,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    setBlogComments(result.data);
  };

  React.useEffect(() => {
    fetchBlogData();
    fetchCommentsData();
  }, []);

  const updateBlogComments = async (id) => {
    await axios.post(
      `${process.env.REACT_APP_API_BASE}/blog/post?id=${id}`,
      {
        id: id,
        title: blogDetails.title,
        subject: blogDetails.subject,
        author: blogDetails.author,
        createDate: blogDetails.createDate,
        comments: blogComments.length + 1,
        text: blogDetails.text,
        coverImage: blogDetails.coverImage,
      },
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
  };

  const postComment = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE}/comment/post?id=${id}`,
        {
          username: user.displayName || user.name,
          avatar: user.photoURL || user.picture.data.url,
          createDate: Date.now(),
          text: userComment,
        },
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response) => {
        toast.success("Comment posted");
        updateBlogComments(id);
        fetchCommentsData();
        setUserComment("");
      })
      .catch((error) => {
        console.log(error);
        toast.warning("Comment NOT posted");
        setUserComment("");
      });
  };

  const returnDate = (date) => {
    const newDate = new Date(date);
    const formatDate = moment(newDate).format("MMMM Do YYYY");
    return formatDate;
  };

  const filterText = (text) => {
    const highlightedTextStart = text.indexOf("/*");
    const highlightedTextStop = text.indexOf("*/");
    if (
      highlightedTextStart > -1 &&
      highlightedTextStop > -1 &&
      highlightedTextStop > highlightedTextStart
    ) {
      const getText = text.substring(
        highlightedTextStart + 2,
        highlightedTextStop
      );
      setHighlightedText(getText);
      getRemainingText(text, highlightedTextStart, highlightedTextStop);
    } else {
      setSplitText1(text);
    }
  };

  const getRemainingText = (
    text,
    highlightedTextStart,
    highlightedTextStop
  ) => {
    if (highlightedTextStart === 0) {
      const splitText = text.substring(highlightedTextStop + 2, text.length);
      setSplitText2(splitText);
    }
    if (highlightedTextStart > 0) {
      const splitText1 = text.substring(0, highlightedTextStart);
      setSplitText1(splitText1);
      const splitText2 = text.substring(highlightedTextStop + 2, text.length);
      setSplitText2(splitText2);
    }
    if (highlightedTextStop + 2 === text.length) {
      const splitText1 = text.substring(0, highlightedTextStart);
      setSplitText1(splitText1);
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setUserComment(e.target.value);
  };
  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              <div className="post format-standard-image">
                <div className="entry-media">
                  {blogDetails && (
                    <img
                      src={blogDetails.coverImage}
                      alt={blogDetails.coverImage}
                    />
                  )}
                </div>
                <div className="entry-meta">
                  <ul>
                    <li>
                      <i className="fi flaticon-user"></i> By{" "}
                      {/* <Link to="/blog-single/1">{blogDetails?.author}</Link>{" "} */}
                      {blogDetails?.author}
                    </li>
                    <li>
                      <i className="fi flaticon-comment-white-oval-bubble"></i>{" "}
                      Comments {blogDetails?.comments}
                    </li>
                    <li>
                      <i className="fi flaticon-calendar"></i>{" "}
                      {blogDetails ? returnDate(blogDetails?.createDate) : ""}
                    </li>
                  </ul>
                </div>
                <h2>{blogDetails?.title}</h2>

                {splitText1 && (
                  <div dangerouslySetInnerHTML={{ __html: splitText1 }}></div>
                )}
                {highlightedText && (
                  <blockquote
                    dangerouslySetInnerHTML={{ __html: highlightedText }}
                  ></blockquote>
                )}

                {splitText2 && (
                  <div dangerouslySetInnerHTML={{ __html: splitText2 }}></div>
                )}

                {/* <div className="gallery">
                  <div>
                    <img src={gl1} alt="" />
                  </div>
                  <div>
                    <img src={gl2} alt="" />
                  </div>
                </div> */}
              </div>

              <div className="tag-share clearfix">
                <div className="tag">
                  <span>Subject: </span>
                  <ul>
                    <li>
                      {/* <Link to={"/blog-single/1"}>{blogDetails?.subject}</Link>
                       */}
                      <div>{blogDetails?.subject}</div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="tag-share-s2 clearfix">
                <div className="tag">
                  <span>Share: </span>
                  <ul>
                    <li>
                      <Link to="/blog-single/1">facebook</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">twitter</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">instagram</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">linkedin</Link>
                    </li>
                  </ul>
                </div>
              </div> */}

              <div className="comments-area">
                <div className="comments-section">
                  <h3 className="comments-title">Comments</h3>
                  <ol className="comments">
                    {blogComments?.map((comment, commentKey) => (
                      <li className="comment" key={commentKey}>
                        <div>
                          <div className="comment-theme">
                            <div className="comment-image">
                              <img src={comment.avatar} alt="" />
                            </div>
                          </div>
                          <div className="comment-main-area">
                            <div className="comment-wrapper">
                              <div className="comments-meta">
                                <h4>
                                  {comment.username}{" "}
                                  <span className="comments-date">
                                    {returnDate(comment.createDate)}
                                  </span>
                                </h4>
                              </div>
                              <div className="comment-area">
                                <p>{comment.text} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
                <div className="comment-respond">
                  <h3 className="comment-reply-title">Post Comments</h3>
                  {user.email ? (
                    <form
                      onSubmit={postComment}
                      id="commentform"
                      className="comment-form"
                    >
                      <div className="form-textarea">
                        <textarea
                          id="comment"
                          placeholder="Write Your Comments..."
                          onChange={handleInputChange}
                          value={userComment}
                          required
                        ></textarea>
                      </div>
                      <div className="form-submit">
                        <input id="submit" value="Post Comment" type="submit" />
                      </div>
                    </form>
                  ) : (
                    <div className="form-submit">
                      <input id="submit" value="Login" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <BlogSidebar blLeft={props.blLeft} /> */}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(BlogSingle);
