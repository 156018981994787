import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import { useParams } from "react-router-dom";
import Packages from "../../api/packages";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
// import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PackageSinglePage = (props) => {
  const { id } = useParams();

  // const packageDetails = Packages.find((item) => item.id === id);
  var settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Navbar2 hclass={"wpo-header-style-2"} topbarBlock={"topbar-block"} />
      <PageTitle pageTitle={Packages[id - 1].package} pagesub={"Packages"} />
      <section className="wpo-portfolio-single-section section-padding">
        <div className="container">
          <div className="portfolio-single-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6 col-12">
                <div className="portfolio-single-img">
                  <img src={Packages[id - 1].prImg} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="portfolio-single-text">
                  <h2>{Packages[id - 1].package}</h2>
                  {/* <span>New York – 1075 Firs Avenue</span> */}
                  <p>{Packages[id - 1].short_description}</p>
                  {/* <p>{Packages[id - 1].description1} </p> */}
                  <div className="wpo-portfolio-single-content-des">
                    <ul>
                      <li>
                        <h6>{Packages[id - 1].description1}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description2}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description3}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description4}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description5}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description6}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description7}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description8}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description9}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description10}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description11}</h6>
                      </li>
                      <li>
                        <h6>{Packages[id - 1].description12}</h6>
                      </li>
                    </ul>

                    {Packages[id - 1].link === "/package-single/4" && (
                      <Link onClick={ClickHandler} to="/product-single/1">
                        <h5>Alege pachetul chiar acum </h5>
                        {/* <i className="fi flaticon-right-arrow-1"></i> */}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="wpo-portfolio-section-s3 section-padding">
            <h2 className="hidden">some</h2>
            <div className="sortable-gallery">
              <div className="row">
                <div className="col-lg-12">
                  <div className="portfolio-grids gallery-container clearfix">
                    <Slider {...settings}>
                      {Packages.map((packages, pitem) => (
                        // {Packages.slice(0, 3).map((packages, pitem) => (
                        <div className="grid" key={pitem + 1}>
                          <div className="img-holder">
                            {/* <ReactFancyBox
                              thumbnail=
                              {packages.prImg}
                              image={packages.prImg}
                            /> */}
                            <h5>{packages.package}</h5>
                            <Link
                              onClick={ClickHandler}
                              to={`/package-single/${packages.id}`}
                            >
                              <img src={packages.prImg} alt={packages.prImg} />
                            </Link>

                            {/* {packages.prImg} */}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="pagi">
            <ul>
              <li>
                {Packages[id - 1].link !== "/package-single/1" && (
                  <Link onClick={ClickHandler} to={Packages[id - 2].link}>
                    <i className="fi flaticon-left-arrow"></i> Previous
                  </Link>
                )}
              </li>
              <li>
                {Packages[id - 1].link !== "/package-single/6" && (
                  <Link onClick={ClickHandler} to={Packages[id].link}>
                    Next <i className="fi flaticon-right-arrow-1"></i>
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PackageSinglePage;
