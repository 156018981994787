import React from "react";
import { Link } from "react-router-dom";
import BlogSidebar from "../BlogSidebar";
import axios from "axios";
import moment from "moment";
import { storage } from "../../main-component/Firebase";
import { ref, deleteObject } from "@firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPen,
  faCalendar,
  faComment,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const BlogList = (props) => {
  const { user } = props.user;
  let history = useHistory();
  const [blogs, setBlogs] = React.useState();
  const [rerender, setRerender] = React.useState(false);
  const token = process.env.REACT_APP_TOKEN;

  const fetchData = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE}/blog/get`,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    setBlogs(result.data);
  };
  React.useEffect(() => {
    if (rerender) {
      fetchData();
      setRerender(false);
    }
  }, [rerender]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const returnDate = (date) => {
    const newDate = new Date(date);
    const formatDate = moment(newDate).format("MMMM Do YYYY");
    return formatDate;
  };

  const sliceText = (text) => {
    const sliceText = text.slice(0, 270).concat("...");
    return sliceText;
  };

  const deleteFromStorage = (id) => {
    const imageRef = ref(storage, `blog/${id}`);
    deleteObject(imageRef);
  };

  const deleteComments = async (id) => {
    await axios.delete(
      `${process.env.REACT_APP_API_BASE}/comments/delete?id=${id}`,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
  };
  const handleDelete = async (e) => {
    e.preventDefault();
    const id = e.target.id;
    await axios
      .delete(`${process.env.REACT_APP_API_BASE}/blog/delete?id=${id}`, {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((response) => {
        deleteFromStorage(id);
        deleteComments(id);
        toast.success("Blog deleted!");
        setRerender(true);
      })
      .catch((error) => {
        console.log(error);
        toast.warning("Blog NOT deleted");
      });
  };

  const handleUpdate = (e) => history.push(`/admin/blog/update/${e.target.id}`);

  return (
    <section className="wpo-blog-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              <div className="check-form-area">
                {blogs?.map((blog, bitem) => (
                  <div className={`post format-standard-image`} key={bitem}>
                    <div className="entry-media video-holder">
                      <img src={blog.coverImage} alt={blog.coverImage} />
                    </div>
                    <div className="entry-meta">
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faUser} /> By{" "}
                          <Link
                            onClick={ClickHandler}
                            to={`/blog-single-fullwidth/${blog.id}`}
                          >
                            {blog.author}
                          </Link>{" "}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faComment} /> Comments{" "}
                          {blog.comments}{" "}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faCalendar} />{" "}
                          {returnDate(blog.createDate)}
                        </li>
                        {user.email === process.env.REACT_APP_ADMIN && (
                          <>
                            <li
                              onClick={handleDelete}
                              className="deleteButton"
                              id={blog.id}
                            >
                              <FontAwesomeIcon icon={faTrash} /> Delete
                            </li>
                            <li
                              onClick={handleUpdate}
                              className="editButton"
                              id={blog.id}
                            >
                              <FontAwesomeIcon icon={faPen} /> Update
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                    <div className="entry-details">
                      <h3>
                        <Link
                          onClick={ClickHandler}
                          to={`/blog-single-fullwidth/${blog.id}`}
                        >
                          {blog.title}
                        </Link>
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sliceText(blog.text),
                        }}
                        style={{ marginBottom: 10 }}
                      ></div>
                      <Link
                        onClick={ClickHandler}
                        to={`/blog-single-fullwidth/${blog.id}`}
                        className="read-more"
                      >
                        READ MORE...
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="pagination-wrapper pagination-wrapper-left">
                <ul className="pg-pagination">
                  <li>
                    <Link to="/blog" aria-label="Previous">
                      <i className="fi ti-angle-left"></i>
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/blog">1</Link>
                  </li>
                  <li>
                    <Link to="/blog">2</Link>
                  </li>
                  <li>
                    <Link to="/blog">3</Link>
                  </li>
                  <li>
                    <Link to="/blog" aria-label="Next">
                      <i className="fi ti-angle-right"></i>
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(BlogList);
