import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: "#fff",
    fontSize: 16,
    fontFamily: "'Jost', sans-serif",
  },
}));

export default useStyles;
