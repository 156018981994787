import React, { Component } from "react";
import Countdown from "reactjs-countdown";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from "../../images/slider/slide-5.jpg";
import hero2 from "../../images/slider/slide-6.jpg";
import hero3 from "../../images/slider/slide-7.jpg";

import heros1 from "../../images/slider/shape7.png";
import heros2 from "../../images/slider/shape8.png";
import ReactFacebookLogin from "react-facebook-login";

class Hero3 extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: true,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
    };

    const responseFacebook = (response) => {
      console.log(response);
    };

    return (
      <div className="wpo-hero-slider wpo-hero-style-3">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <Slider {...settings}>
              <div className="hero-slide">
                <div
                  className="slide-inner slide-bg-image"
                  style={{ backgroundImage: `url(${hero1})` }}
                >
                  <div className="container-fluid">
                    <div className="slide-content">
                      <div className="slide-title-sub">
                        <span>
                          <i>
                            <img src={heros1} alt="" />
                          </i>
                          Welcome To Arisa Events
                          <i>
                            <img src={heros2} alt="" />
                          </i>
                        </span>
                        {/* <span>
                                                    <ReactFacebookLogin
                                                        appId="508541633986697"
                                                        autoLoad={true}
                                                        fields="name,email,picture"
                                                        // onClick={componentClicked}
                                                        callback={responseFacebook} />
                                                </span> */}
                      </div>
                      <div className="slide-title">
                        <h2>
                          Happily ever after is not a Fairytale. It's a choice.
                        </h2>
                      </div>
                      <div className="clearfix"></div>
                      {/* <div className="slide-btns">
                                                <Link to="/about" className="theme-btn">Plan Your Wedding</Link>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="hero-slide">
                <div
                  className="slide-inner slide-bg-image"
                  style={{ backgroundImage: `url(${hero2})` }}
                >
                  <div className="container-fluid">
                    <div className="slide-content">
                      <div className="slide-title-sub">
                        <span>
                          <i>
                            <img src={heros1} alt="" />
                          </i>
                          Make your dreams come true.
                          <i>
                            <img src={heros2} alt="" />
                          </i>
                        </span>
                      </div>
                      <div className="slide-title-sub">
                        <span>
                          <i>With Arisa Events say I Do!</i>
                        </span>
                      </div>
                      <div className="slide-title">
                        <h2>Great things coming soon.</h2>
                      </div>
                      <div className="clearfix"></div>
                      {/* <div className="slide-btns">
                                                <Link to="/about" className="theme-btn">Plan Your Wedding</Link>
                                            </div> */}
                      <div className="coming-soon-section">
                        <div className="coming-soon-item">
                          <div className="wpo-wedding-date">
                            <h2 className="hidden">some</h2>
                            {/* <div className="clock-grids">
                              <Countdown deadline="June 1, 2022" />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="hero-slide">
                                <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero3})` }}>
                                    <div className="container-fluid">
                                        <div className="slide-content">
                                            <div className="slide-title-sub">
                                                <span>
                                                    <i><img src={heros1} alt="" /></i>
                                                    Welcome To Feelings
                                                    <i><img src={heros2} alt="" /></i>
                                                </span>
                                            </div>
                                            <div className="slide-title">
                                                <h2>Making Memory That Last Forever</h2>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="slide-btns">
                                                <Link to="/about" className="theme-btn">Plan Your Wedding</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero3;
