import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link, withRouter, useHistory } from "react-router-dom";
import "./style.scss";

import { auth } from "../Firebase";

const SignUpPage = (props) => {
  let history = useHistory();
  const [value, setValue] = useState({
    email: "",
    full_name: "",
    password: "",
    confirm_password: "",
  });

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  // const handle_facebook_login = (e) => {
  //   e.preventDefault();
  //   auth
  //     .signInWithPopup(provider_fb)
  //     .then((result) => {
  //       // console.log(result.additionalUserInfo.profile);
  //       props.addUser(result.additionalUserInfo.profile);
  //       toast.success("You successfully Login on Arisa Events!");
  //       history.push("/");
  //     })
  //     .catch((error) => toast.error(error.message));
  // };

  // const handle_google_login = (e) => {
  //   e.preventDefault();

  //   auth
  //     .signInWithPopup(provider_google)
  //     .then((result) => {
  //       props.addUser(result.user);
  //       // props.loggedIn = false;
  //       history.push("/home");
  //       // console.log(result.user.email);
  //       // toast.success('You .'+ props.user.user.displayName +' successfully on Arisa Events!');
  //     })
  //     .catch((error) => toast.error(error.message));
  // };

  const submitForm = (e) => {
    console.log(e);
    e.preventDefault();
    if (validator.allValid()) {
      setValue({
        email: "",
        full_name: "",
        password: "",
        confirm_password: "",
      });
      validator.hideMessages();
      auth
        .createUserWithEmailAndPassword(value.email, value.password)
        .then((auth) => {
          history.push("/login");
          toast.success("Registration Complete successfully!");
        })
        .catch((e) => toast.error(e.message));
    } else {
      validator.showMessages();
      toast.error("Empty field is not allowed!");
    }
  };
  return (
    <Grid className="loginWrapper">
      <Grid className="loginForm">
        <h2>Sign Up</h2>
        <p>Sign up your account</p>
        <form onSubmit={submitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="Full Name"
                value={value.full_name}
                variant="outlined"
                name="full_name"
                label="Name"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message(
                "full name",
                value.full_name,
                "required|alpha"
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="E-mail"
                value={value.email}
                variant="outlined"
                name="email"
                label="E-mail"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("email", value.email, "required|email")}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="Password"
                value={value.password}
                variant="outlined"
                name="password"
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("password", value.password, "required")}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="Confirm Password"
                value={value.confirm_password}
                variant="outlined"
                name="confirm_password"
                label="Confirm Password"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message(
                "confirm password",
                value.confirm_password,
                `in:${value.password}`
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid className="formFooter">
                <Button
                  fullWidth
                  className="cBtn cBtnLarge cBtnTheme"
                  type="submit"
                >
                  Sign Up
                </Button>
              </Grid>
              {/* <Grid className="loginWithSocial">
                <Button className="facebook" onClick={handle_facebook_login}>
                  <i className="fa fa-facebook"></i>
                </Button>
                <Button className="google" onClick={handle_google_login}>
                  <i className="fa fa-google"></i>
                </Button> */}
              {/* <Button className="linkedin">
                  <i className="fa fa-linkedin"></i>
                </Button> */}
              {/* </Grid> */}
              <p className="noteHelp">
                Already have an account?{" "}
                <Link to="/login">Return to Sign In</Link>
              </p>
            </Grid>
          </Grid>
        </form>
        <div className="shape-img">
          <i className="fi flaticon-honeycomb"></i>
        </div>
      </Grid>
    </Grid>
  );
};

export default withRouter(SignUpPage);
