import { ADD_BLOG_INFO } from "../actions/type";

const initialState = {
  blog: [],
};

export const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BLOG_INFO:
      return {
        ...state,
        blog: action.blog,
      };
    default:
      return state;
  }
};
export default blogReducer;
