import React from 'react'
import { Link } from 'react-router-dom'

const HeaderTopbar = () => {
    const handlemail = () => {
        const mailto =
          "mailto:office@arisaevents.com?subject=Arisa Events - Informatii&body= ";
        window.location.href = mailto;
      }

    const handletel = () => {
        const telto =
          "tel:+40748958596";
        window.location.href = telto;
      }

    return (
        <section className="topbar">
            <h2 className="hidden">some</h2>
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-lg-7 col-md-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li onClick={handlemail} className="custom-email-icon"><i className="fi flaticon-email custom-rose">
                                </i>office@arisaevents.com</li>
                                <li onClick={handletel} className="custom-tel-icon">
                                    <i className="fi flaticon-phone-call custom-rose"></i>+40 748 958 596</li>
                                <li><i className="fi flaticon-maps-and-flags"></i>Sibiu, Romania
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-5 col-md-12 col-12">
                        <div className="contact-info">
                            <ul>
                                {/* <li><Link to="/"><i className="fi flaticon-facebook-app-symbol"></i></Link></li>
                                <li><Link to="/"><i className="fi flaticon-twitter"></i></Link></li>
                                <li><Link to="/"><i className="fi flaticon-linkedin"></i></Link></li>
                                <li><Link to="/"><i className="fi flaticon-instagram-1"></i></Link></li>
                                <li><Link to="/"><i className="fi flaticon-pinterest"></i></Link></li>
                                <li><Link to="/"><i className="fi flaticon-youtube"></i></Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeaderTopbar;