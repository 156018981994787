// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAI1eJEPRxLSA5q6b000NV9tsiGZu4om0o",
  authDomain: "arisa-events.firebaseapp.com",
  projectId: "arisa-events",
  storageBucket: "arisa-events.appspot.com",
  messagingSenderId: "544816106029",
  appId: "1:544816106029:web:fb58eda0015c9db759c6a4",
  measurementId: "G-CMHZLG838S",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const provider_fb = new firebase.auth.FacebookAuthProvider();
const provider_google = new firebase.auth.GoogleAuthProvider();
const provider_apple = new firebase.auth.GoogleAuthProvider();
const storage = getStorage();

export {
  auth,
  provider,
  provider_fb,
  provider_google,
  provider_apple,
  storage,
};

export default db;
