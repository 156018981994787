import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2";
import CheckoutSection from "../../components/CheckoutSection";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  auth,
  provider_fb,
  provider_google,
  provider_apple,
} from "../Firebase";

const CheckoutPage = ({ cartList, user }) => {
  let history = useHistory();
  if (user.user?.email) {
    return (
      <Fragment>
        <Navbar2 hclass={"wpo-header-style-2"} topbarBlock={"topbar-block"} />
        <PageTitle pageTitle={"Checkout"} pagesub={"Checkout"} />
        <CheckoutSection cartList={cartList} user={user} />
        <Footer />
        <Scrollbar />
      </Fragment>
    );
  }
  return <Redirect to="/login" />;
};
const mapStateToProps = (state) => {
  return {
    cartList: state.cartList.cart,
    symbol: state.data.symbol,
    user: state.user,
  };
};

export default connect(mapStateToProps)(CheckoutPage);
