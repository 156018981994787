import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import AdminCreateBlogForm from "../AdminCreateBlogForm";
import AdminCreateProductForm from "../AdminCreateProductForm";
import { useSelector } from "react-redux";

const Contactpage = () => {
  const [step, setStep] = useState(1);
  const { user } = useSelector((store) => store);

  const setDisplayName = () => {
    let name;
    if (user.user.displayName) name = user.user.displayName;
    return name;
  };

  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <Button
            onClick={() => setStep(1)}
            variant={step === 1 ? "contained" : "outlined"}
            style={{ marginRight: 10 }}
            size="large"
          >
            Blog post
          </Button>
          <Button
            onClick={() => setStep(2)}
            variant={step === 2 ? "contained" : "outlined"}
            size="large"
          >
            Add product
          </Button>
        </Box>
        {step === 1 && (
          <div className="row admin-form">
            <div className="col col-lg-10 offset-lg-1">
              <div className="wpo-contact-title">
                <h2>Add blog</h2>
              </div>
              <div className="wpo-contact-form-area ">
                <AdminCreateBlogForm name={setDisplayName()} />
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="row admin-form">
            <div className="col col-lg-10 offset-lg-1">
              <div className="wpo-contact-title">
                <h2>Add product</h2>
              </div>
              <div className="wpo-contact-form-area admin-form">
                <AdminCreateProductForm />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Contactpage;
