import axios from "axios";
import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useParams } from "react-router-dom";

const Product = ({ addToCart }) => {
  const { id } = useParams();
  const [product, setProduct] = React.useState();
  const fetchData = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE}/product/id/get?id=${id}`,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    setProduct(result.data);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row align-items-center">
      <div className="col col-lg-5 col-12">
        <div className="shop-single-slider">
          <div className="slider-nav">
            <div>
              <Zoom>
                <img src={product?.proImg} alt="products" />
              </Zoom>
            </div>
          </div>
        </div>
      </div>
      <div className="col col-lg-7 col-12">
        <div className="product-details">
          <h2>{product?.title}</h2>
          {/* <div className="product-rt">
            <div className="rating">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star-o"></i>
            </div>
             <span>(25 customer reviews)</span> 
          </div> */}
          <div className="price">
            <span className="current">{product?.price} RON</span>
            {product?.oldPrice && (
              <span className="old">{product.oldPrice} RON</span>
            )}
          </div>
          <div dangerouslySetInnerHTML={{ __html: product?.description }}></div>
          {/* <ul>
            <li>Going through the cites of the word in classNameical.</li>
            <li>There are many variations of passages.</li>
            <li>Making it look like readable and spoken English.</li>
          </ul> */}
          <div className="product-option">
            <div className="product-row">
              <button className="theme-btn" onClick={() => addToCart(product)}>
                Add to cart
              </button>
              <div></div>
            </div>
          </div>
          <div className="tg-btm">
            <p>
              <span>Categories:</span> {product?.categories}
            </p>
            {/* <p>
              <span>Tags:</span> Jewellery, events, wedding
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
