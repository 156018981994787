import axios from "axios";
import React from "react";
import { Link, useParams } from "react-router-dom";

const PageTitle = (props) => {
  // const { id } = useParams();
  // const [blogDetails, setBlogDetails] = React.useState();
  // //   const BlogDetails = blogs.find((item) => item.id === id);
  // const fetchBlogData = async () => {
  //   const result = await axios(
  //     `${process.env.REACT_APP_API_BASE}/blog/id/get?id=${id}`
  //   );
  //   setBlogDetails(result.data);
  // };

  // React.useEffect(() => {
  //   fetchBlogData();
  // }, []);
  return (
    <section className="wpo-page-title">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="wpo-breadcumb-wrap">
              {/* <h2>{blogDetails?.title}</h2> */}
              <ol className="wpo-breadcumb-wrap">
                <li>
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  <span>{props.pagesub}</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
