import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { storage } from "../../main-component/Firebase";
import { ref, deleteObject } from "@firebase/storage";
import { useState } from "react";

const ShopProduct = ({ products, addToCartProduct, user }) => {
  let history = useHistory();
  const [productsData, setProductsData] = React.useState();
  const [rerender, setRerender] = React.useState(false);
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const fetchData = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE}/product/get`,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    setProductsData(result.data);
  };

  React.useEffect(() => {
    setProductsData(products);
  }, [products]);

  React.useEffect(() => {
    if (rerender) {
      fetchData();
      setRerender(false);
    }
  }, [rerender]);

  const deleteFromStorage = (id) => {
    const imageRef = ref(storage, `product/${id}`);
    deleteObject(imageRef);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const id = e.target.id;
    await axios
      .delete(`${process.env.REACT_APP_API_BASE}/product/delete?id=${id}`, {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((response) => {
        deleteFromStorage(id);
        toast.success("Product deleted!");
        setRerender(true);
      })
      .catch((error) => {
        console.log(error);
        toast.warning("Product NOT deleted");
      });
  };

  const handleUpdate = (e) =>
    history.push(`/admin/product/update/${e.target.id}`);

  return (
    <section className="wpo-product-section section-padding">
      <div className="container">
        <div className="wpo-product-wrap">
          <div className="row">
            {productsData?.length > 0 &&
              productsData.map((product, pitem) => (
                <div className="col-xl-4 col-lg-6 col-md-6 col-12" key={pitem}>
                  <div className="wpo-product-item">
                    <div className="wpo-product-img">
                      <img src={product.proImg} alt="" />
                      <button
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title="Add to Cart"
                        onClick={() => addToCartProduct(product)}
                      >
                        <i className="fi flaticon-shopping-cart"></i>
                      </button>
                    </div>
                    <div className="wpo-product-text">
                      <h3>
                        <Link
                          onClick={ClickHandler}
                          to={`/product-single/${product.id}`}
                        >
                          {product.title}
                        </Link>
                      </h3>
                      <span>{product.price} RON</span>
                      {user?.user.email === process.env.REACT_APP_ADMIN && (
                        <ul className="updateDeleteProduct">
                          <li
                            onClick={handleDelete}
                            className="deleteButton"
                            id={product.id}
                          >
                            <FontAwesomeIcon icon={faTrash} /> Delete
                          </li>
                          <li
                            onClick={handleUpdate}
                            className="editButton"
                            id={product.id}
                          >
                            <FontAwesomeIcon icon={faPen} /> Update
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(ShopProduct);
