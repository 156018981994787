import axios from "axios";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { storage } from "../../main-component/Firebase";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";

function AdminUpdateBlogForm(props) {
  const { user } = props.user;
  let history = useHistory();
  const { id } = useParams();
  const [blogDetails, setBlogDetails] = React.useState();
  const [submit, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState();
  const token = process.env.REACT_APP_TOKEN;
  const editorRef = React.useRef(null);

  const fetchBlogData = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE}/blog/id/get?id=${id}`,
      {
        headers: {
          Authorization: `Basic ${token}`,
        },
      }
    );
    setBlogDetails(result.data);
  };

  React.useEffect(() => {
    fetchBlogData();
  }, []);

  const changeHandler = (e) => {
    e.preventDefault();
    setBlogDetails({
      ...blogDetails,
      [e.target.name]: e.target.value,
    });
  };

  const updatText = (value) => {
    setBlogDetails({
      ...blogDetails,
      text: value,
    });
  };

  const subimtHandler = (e) => {
    setError();
    e.preventDefault();
    checkForErrors();
    setSubmitting(true);
  };

  const updateBlog = async () => {
    if (blogDetails.base64URL) {
      const imageRef = ref(storage, `blog/${blogDetails.id}`);
      await uploadString(imageRef, blogDetails.base64URL, "data_url").then(
        async (snapshot) => {
          blogDetails.coverImage = await getDownloadURL(imageRef);
          postRequest();
        }
      );
    } else postRequest();
  };

  const postRequest = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE}/blog/post?id=${blogDetails.id}`,
        {
          id: blogDetails.id,
          title: blogDetails.title,
          subject: blogDetails.subject,
          author: blogDetails.author,
          createDate: blogDetails.createDate,
          comments: blogDetails.comments,
          text: blogDetails.text,
          coverImage: blogDetails.coverImage,
        },
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response) => {
        toast.success("Blog update sucessfully!");
        setSubmitting(false);
        history.push(`/blog-single-fullwidth/${blogDetails.id}`);
      })
      .catch((error) => {
        console.log(error);
        toast.warning("Blog NOT update");
      });
  };

  React.useEffect(() => {
    if (!error && submit) {
      updateBlog();
    }
  }, [submit, error]);

  const checkForErrors = () => {
    if (blogDetails.author.length === 0) {
      setError({ ...error, author: "Please add an author" });
    }
    if (blogDetails.title.length === 0) {
      setError({ ...error, title: "Please add a title" });
    }
    if (blogDetails.text.length === 0) {
      setError({ ...error, text: "Please add a text" });
    }
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setBlogDetails({ ...blogDetails, base64URL: result });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <>
      {user.email === process.env.REACT_APP_ADMIN ? (
        <section className="wpo-contact-pg-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-lg-10 offset-lg-1">
                <div className="wpo-contact-title">
                  <h2>Edit blog</h2>
                </div>
                <div className="wpo-contact-form-area">
                  <form onSubmit={subimtHandler} className="form">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                          <input
                            value={blogDetails?.author || " "}
                            onChange={changeHandler}
                            type="text"
                            name="author"
                            placeholder="Author"
                          />
                          {error?.author && <p>{error.author}</p>}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                          <input
                            onChange={changeHandler}
                            value={blogDetails?.title || " "}
                            type="text"
                            name="title"
                            placeholder="Title"
                          />
                          {error?.title && <p>{error.title}</p>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div>
                          <select
                            name="subject"
                            className="form-control"
                            value={blogDetails?.subject || " "}
                            onChange={changeHandler}
                          >
                            <option>Subject</option>
                            <option>Wedding</option>
                            <option>Wedding dress</option>
                            <option>Wedding song</option>
                            <option>Bride</option>
                            <option>Bride shoes</option>
                            <option>Groom</option>
                            <option>Engagement</option>
                            <option>Concept</option>
                            <option>Flowers</option>
                            <option>Photography</option>
                            <option>Music</option>
                            <option>Entertaiment</option>
                            <option>DJ & Bands</option>
                            <option>Cakes</option>
                            <option>Bachelorette party</option>
                            <option>Clothing</option>
                            <option>Traditions</option>
                            <option>Print</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                          <img
                            src={
                              blogDetails?.base64URL
                                ? blogDetails?.base64URL
                                : blogDetails?.coverImage
                            }
                            alt=""
                          />
                          <input
                            onChange={uploadImage}
                            type="file"
                            name="imageName"
                            placeholder="Cover image"
                            accept="image/png, image/gif, image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-field">
                          {/* <textarea
                            name="text"
                            placeholder="Text"
                            value={blogDetails?.text || " "}
                            onChange={changeHandler}
                          ></textarea> */}
                          <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue={blogDetails?.text || " "}
                            onEditorChange={(value) => updatText(value)}
                            init={{
                              height: 500,
                              menubar: false,
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                              ],
                              toolbar:
                                "undo redo | blocks | " +
                                "bold italic forecolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | " +
                                "removeformat | help",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                          />
                          {error?.text && <p>{error.text}</p>}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-submit">
                          <button type="submit" className="theme-btn-s4 mt-4">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <h1>Admin Only</h1>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(AdminUpdateBlogForm);
