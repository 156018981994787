import {
    ADD_USER_INFO,
  } from "../actions/type";
  
  const initialState = {
    user: [],
    loggedIn: false,
  };
  
  export const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_USER_INFO:
        return {
            ...state,
            user: action.user
        };
      default:
        return state;
    }
  };
  export default userReducer;
  