import PrImg2 from "../images/pricing/package2.jpg";
import PrImg3 from "../images/pricing/package3.jpg";
import PrImg4 from "../images/pricing/package4.jpg";
import PrImg5 from "../images/pricing/package_corporate.jpg";
import PrImg1 from "../images/pricing/package1.jpg";
import PrImg6 from "../images/pricing/package6.jpg";

const Packages = [
  {
    id: 1,
    prImg: PrImg1,
    package: "Pachetul ZEN",
    label: "Include servicii complete de organizare a nuntii.",
    short_description:
      "Pachetul Zen include servicii complete. Suntem implicați în planificarea nunții de la început până la sfârșit. Găsim și angajăm furnizori și locații, ne ocupăm de găsirea invitațiilor, schițăm un plan și un program și direcționăm totul și pe toată lumea în ziua nunții.",
    description1: "Recomandari pentru alegerea furnizorilor potriviti",
    description2:
      "Contactarea, coordonarea si organizarea furnizorilor inainte si in timpul evenimentului",
    description3:
      "Realizarea unui orar cu sarcinile si perioada in care trebuie indeplinite",
    description4:
      "Stabilirea unui program de eveniment care cuprinde toate momentele importante si detaliile evenimentului",
    description5:
      "Recomandari in alegerea unor marturii/cadouri pentru invitati",
    description6:
      "Conceperea unei proiectii de buget si monitorizarea cheltuielilor conform deciziilor agreate de comun acord",
    description7:
      "Planificarea si participarea la vizitele restaurantelor, precum si la degustarile de meniuri/prajituri/bauturi conform specificatiilor financiare si de calitate primite",
    description8:
      "Crearea conceptului si alegerea tematicii evenimentului astfel incat acestea sa se potriveasca cu cerintele clientilor",
    description9:
      "Organizarea serviciilor de transport si cazare pentru invitatii din afara orasului",
    description10: "Coordonarea in ziua evenimentului",
    description11:
      "Programarea la saloane de rochii si costume si asistarea la doua dintre acestea",
    description12: "Suport 24/7 pe toata durata pregatirilor pana la eveniment",
    link: "/package-single/1",
  },
  {
    id: 2,
    prImg: PrImg2,
    package: "Pachetul ARMONIA",
    label: "Include servicii partiale de organizare a nuntii.",
    short_description:
      "Pachetul Armonia include servicii parțiale. Ati stabilit deja toate detaliile, ati gasit furnizorii, dar simtiti ca aveti nevoie de cineva care sa se ocupe de toate problemele care pot apărea in ziua nuntii? Înainte cu 2 luni de marele eveniment, preluăm totul și ne ocupăm de verificarea contractelor, găsim furnizori daca mai este nevoie sau luam legătura cu furnizorii angajați deja. Verificăm logistica, planificăm orarul și coordonăm totul în ziua cea mare.",
    description1: "Recomandari pentru alegerea furnizorilor potriviti",
    description2:
      "Contactarea, coordonarea si organizarea furnizorilor inainte si in timpul evenimentului",
    description3:
      "Realizarea unui orar cu sarcinile si perioada in care trebuie indeplinite",
    description4:
      "Stabilirea unui program de eveniment care cuprinde toate momentele importante si detaliile evenimentului",
    description5:
      "Recomandari in alegerea unor marturii/cadouri pentru invitati",
    description6:
      "Conceperea unei proiectii de buget si monitorizarea cheltuielilor conform deciziilor agreate de comun acord",
    description7:
      "Planificarea si participarea la vizitele restaurantelor, precum si la degustarile de meniuri/prajituri/bauturi conform specificatiilor financiare si de calitate primite",
    description8:
      "Crearea conceptului si alegerea tematicii evenimentului astfel incat acestea sa se potriveasca cu cerintele clientilor",
    description9:
      "Organizarea serviciilor de transport si cazare pentru invitatii din afara orasului",
    description10: "Coordonarea in ziua evenimentului",
    description11:
      "Programarea la saloane de rochii si costume si asistarea la doua dintre acestea",
    description12: "Suport 24/7 pe toata durata pregatirilor pana la eveniment",

    link: "/package-single/2",
  },
  {
    id: 3,
    prImg: PrImg3,
    package: "Pachetul BASIC",
    label: "Include servicii de baza de organizare a nuntii.",
    short_description:
      "Pachetul Basic include servicii de baza. Daca ati inceput deja organizarea nuntii voastre dar v-ati blocat sau pur si simplu aveti nevoie de ajutor de acum incolo, noi vom prelua totul de unde ati ramas, vom gasi impreuna furnizori ide care mai aveti nevoie, luam legatura cu furnizorii angajati deja, panificam oralul si coordonam totul in ziua marelui eveniment.",
    description1: "Recomandari pentru alegerea furnizorilor potriviti",
    description2:
      "Contactarea, coordonarea si organizarea furnizorilor inaintea evenimentului",
    description3:
      "Realizarea unui orar cu sarcinile si perioada in care trebuie indeplinite",
    description4:
      "Stabilirea unui program de eveniment care cuprinde toate momentele importante si detaliile evenimentului",
    description5:
      "Recomandari in alegerea unor marturii/cadouri pentru invitati",
    description6:
      "Conceperea unei proiectii de buget si monitorizarea cheltuielilor conform deciziilor agreate de comun acord",
    description7: "Suport 24/7 pe toata durata pregatirilor pana la eveniment",
    link: "/package-single/3",
  },
  {
    id: 4,
    prImg: PrImg4,
    package: "Pachetul ARISA",
    label: "Include servicii de consultant pentru evenimentul tau.",
    short_description:
      "Pachetul Arisa include servicii de consultanță. Dacă vreti sa va organizati singuri nunta sau ați planificat deja totul dar aveti nevoie de un ghid pentru a va oferi niște sfaturi sunt alături de voi prin intermediul Platformei online Zoom/Skype iar timp de o oră vom discuta despre orice veți avea nevoie, cum ar fi:",
    description1: "Alegerea furnizorilor potriviti",
    description2: "Recomandari in alegerea marturiilor pentru invitati",
    description3: "Recomandari in alegerea printurilor",
    description4: "Alegerea melodiei pentru dansul mirilor",
    description5: "Tips & Tricks pentru ziua cea mare",
    description6: "Recomandari in alegerea unui concept si a unei tematici",
    link: "/package-single/4",
  },
  {
    id: 5,
    prImg: PrImg5,
    package: "Pachetul CORPORATE",
    label:
      "Include servicii de organizare complete a eventimentului companiei tale.",
    short_description:
      "Pachetul Corporate include servicii de organizare completa a evenimentului companiei tale. Daca te-ai gandit sa organizezi o petrecere pentru angajatii tai, fie ca este vorba de o firma mica sau o multinationala, noi ne ocupam de tot ce este nevoie pentru a avea un eveniment reusit, de la contactarea furnizorilor potriviti pentru eveniment pana la aranjarea salii si coordonarea in ziua evenimentului. ",
    description1: "Recomandari pentru alegerea furnizorilor potriviti",
    description2:
      "Contactarea, coordonarea si organizarea furnizorilor inainte si in timpul evenimentului",
    description3:
      "Stabilirea unui program de eveniment care cuprinde toate momentele importante si detaliile evenimentului",
    description4:
      "Planificarea si participarea la vizitele restaurantelor, precum si la degustarile de meniuri/prajituri/bauturi conform specificatiilor financiare si de calitate primite",
    description5:
      "Crearea conceptului si alegerea tematicii evenimentului astfel incat acestea sa se potriveasca cu cerintele clientilor",
    description6:
      "Organizarea serviciilor de transport si cazare pentru invitatii din afara orasului",
    description7: "Coordonarea in ziua evenimentului",
    description8: "Suport 24/7 pe toata durata pregatirilor pana la eveniment",
    link: "/package-single/5",
  },
  {
    id: 6,
    prImg: PrImg6,
    package: "Pachetul SPECIAL",
    label:
      "Include servicii complete de organizare a orcaruit alt tip de eveniment.",
    short_description:
      "Pachetul Special include organizarea oricarui tip de eveniment special din viata ta, cum ar fi cununia civila, botezul copilului tau, aniversarea ta sau a unui membru al familiei, petrecerea burlacitelor sau a burlacilor, chiar si Gender Reveal-ul copilului vostru sau Baby Shower pentru viitoarea mamica. Suntem aici sa ne ocupam de toata logisitca si detaliile importante pentru a avea un eveniment de neuitat. ",
    description1: "Recomandari pentru alegerea furnizorilor potriviti",
    description2:
      "Contactarea, coordonarea si organizarea furnizorilor inainte si in timpul evenimentului",
    description3:
      "Realizarea unui orar cu sarcinile si perioada in care trebuie indeplinite",
    description4:
      "Stabilirea unui program de eveniment care cuprinde toate momentele importante si detaliile evenimentului",
    description5:
      "Recomandari in alegerea unor marturii/cadouri pentru invitati daca este nevoie",
    description6:
      "Conceperea unei proiectii de buget si monitorizarea cheltuielilor conform deciziilor agreate de comun acord",
    description7:
      "Planificarea si participarea la vizitele restaurantelor, precum si la degustarile de meniuri/prajituri/bauturi conform specificatiilor financiare si de calitate primite",
    description8:
      "Crearea conceptului si alegerea tematicii evenimentului astfel incat acestea sa se potriveasca cu cerintele clientilor",
    description9:
      "Organizarea serviciilor de transport si cazare pentru invitatii din afara orasului",
    description10: "Coordonarea in ziua evenimentului",
    description11: "Suport 24/7 pe toata durata pregatirilor pana la eveniment",
    link: "/package-single/6",
  },
];
export default Packages;
