import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2";
import PageTitle from "../../components/pagetitle";
import BlogSingle from "../../components/BlogDetails";
import Scrollbar from "../../components/scrollbar";
import Footer from "../../components/footer";

const BlogDetailsFull = () => {
  return (
    <Fragment>
      <Navbar2 hclass={"wpo-header-style-2"} topbarBlock={"topbar-block"} />
      <PageTitle pagesub={"Blog"} />
      <BlogSingle blLeft={"d-none"} blRight={"col-lg-10 offset-lg-1"} />
      <Scrollbar />
      <Footer />
    </Fragment>
  );
};
export default BlogDetailsFull;
