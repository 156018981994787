import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2";
import PageTitle from "../../components/pagetitle";
import AdminUpdateBlogForm from "../../components/AdminUpdateBlogForm";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";

const AdminUpdateBlog = () => {
  return (
    <Fragment>
      <Navbar2 hclass={"wpo-header-style-2"} topbarBlock={"topbar-block"} />
      <PageTitle pageTitle={"Update Blog"} pagesub={"Admin"} />
      <AdminUpdateBlogForm />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AdminUpdateBlog;
