import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2";
import PageTitle from "../../components/pagetitle";
import BlogList from "../../components/BlogList";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";

import { connect } from "react-redux";

const BlogPageLeft = ({ blog }) => {
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={"Latest News"} pagesub={"Blog"} />
      <BlogList blLeft={"order-lg-1"} blRight={"order-lg-2"} blog={blog} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    blog: state.blog,
  };
};

export default connect(mapStateToProps)(BlogPageLeft);
