import axios from "axios";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { ref, uploadString, getDownloadURL } from "@firebase/storage";
import { storage } from "../../main-component/Firebase";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";

class AdminCreateBlogForm extends Component {
  state = {
    author: "",
    title: "",
    subject: "",
    coverImage: "",
    text: "",
    base64URL: "",
    error: {},
    blogsCount: 0,
    imageName: "",
    test: null,
  };

  editorRef = React.createRef(null);
  inputRef = React.createRef();
  getBlogsCount = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE}/blog/get`,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    this.state.blogsCount = result.data[result.data.length - 1].id;
  };

  componentDidMount = async () => {
    this.getBlogsCount();
    const error = this.state.error;
    error.author = "";

    this.setState({
      ...this.state,
      author: this.props.name,
      error,
    });
  };

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  changeText = (value) => {
    const error = this.state.error;
    error.text = "";
    this.setState({
      text: value,
      error,
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  uploadImage = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";
    let { file } = this.state;
    file = e.target.files[0];
    this.setState({
      ...this.state,
      test: e.target.files[0],
    });
    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        this.setState({
          base64URL: result,
          error,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.changeHandler(e);
  };

  createBlog = async () => {
    const imageRef = ref(
      storage,
      `blog/${parseInt(this.state.blogsCount) + 1}`
    );
    await uploadString(imageRef, this.state.base64URL, "data_url").then(
      async (snapshot) => {
        this.state.coverImage = await getDownloadURL(imageRef);
        this.postRequest();
      }
    );
  };

  postRequest = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE}/blog/post?id=${
          parseInt(this.state.blogsCount) + 1
        }`,
        {
          id: parseInt(this.state.blogsCount) + 1,
          title: this.state.title,
          subject: this.state.subject,
          author: this.state.author,
          createDate: Date.now(),
          comments: 0,
          text: this.state.text,
          coverImage: this.state.coverImage,
        },
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response) => {
        toast.success("Blog created sucessfully!");
        this.resetStateValues();
        this.getBlogsCount();
        this.props.history.push(
          `/blog-single-fullwidth/${parseInt(this.state.blogsCount) + 1}`
        );
      })
      .catch((error) => {
        console.log(error);
        toast.warning("Blog NOT created");
        this.resetStateValues();
      });
  };

  resetStateValues = () => {
    this.setState({
      author: "",
      title: "",
      subject: "",
      text: "",
      error: {},
      coverImage: "",
      imageName: "",
    });
    this.inputRef.current.value = null;
  };

  subimtHandler = (e) => {
    e.preventDefault();
    const { error, author, title, subject, imageName, text } = this.state;

    if (!author) {
      error.author = "Please enter your name";
    }
    if (!title) {
      error.title = "Please enter your title";
    }
    if (!subject) {
      error.subject = "Please enter your subject";
    }
    if (!imageName) {
      error.imageName = "Please enter your cover image";
    }
    if (!text) {
      error.text = "Please enter your text";
    }
    if (error) {
      this.setState({
        error,
      });
      console.log(error);
    }
    if (
      error.author === "" &&
      error.title === "" &&
      error.subject === "" &&
      error.text === "" &&
      error.imageName === ""
    ) {
      this.createBlog();
    }
  };

  render() {
    const { author, title, subject, text, error } = this.state;

    const { name } = this.props;
    return (
      <form onSubmit={this.subimtHandler} className="form">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="form-field">
              <input
                onChange={this.changeHandler}
                value={title}
                type="text"
                name="title"
                placeholder="Title"
              />
              <p>{error.title ? error.title : ""}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                value={author}
                onChange={this.changeHandler}
                type="text"
                name="author"
                placeholder="Author"
              />
              <p>{error.author ? error.author : ""}</p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12">
            <div>
              <select
                name="subject"
                className="form-control"
                value={subject}
                onChange={this.changeHandler}
              >
                <option>Subject</option>
                <option>Wedding</option>
                <option>Wedding dress</option>
                <option>Wedding song</option>
                <option>Bride</option>
                <option>Bride shoes</option>
                <option>Groom</option>
                <option>Engagement</option>
                <option>Concept</option>
                <option>Flowers</option>
                <option>Photography</option>
                <option>Music</option>
                <option>Entertaiment</option>
                <option>DJ & Bands</option>
                <option>Cakes</option>
                <option>Bachelorette party</option>
                <option>Clothing</option>
                <option>Traditions</option>
                <option>Print</option>
              </select>
              <p>{error.subject ? error.subject : ""}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-field">
              {/* <textarea
                name="text"
                placeholder="Text"
                value={text}
                onChange={this.changeHandler}
              ></textarea> */}
              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                onInit={(evt, editor) => (this.editorRef.current = editor)}
                onEditorChange={(value) => this.changeText(value)}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
              <p>{error.text ? error.text : ""}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <Button
                variant="contained"
                onClick={() => this.inputRef.current.click()}
              >
                Upload file
              </Button>
              <input
                onChange={this.uploadImage}
                type="file"
                ref={this.inputRef}
                name="imageName"
                placeholder="Cover image"
                accept="image/png, image/gif, image/jpeg"
                hidden
              />
              <p>{error.imageName ? error.imageName : ""}</p>
              {this.state.test && (
                <div
                  style={{
                    width: 300,
                    maxHeight: 300,
                    borderRadius: 8,
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{ width: "auto" }}
                    src={URL.createObjectURL(this.state.test)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-submit">
              <button type="submit" className="theme-btn-s4 mt-4">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
export default withRouter(AdminCreateBlogForm);
