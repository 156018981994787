import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2";
import PageTitle from "../../components/pagetitle";
import AdminUpdateProductForm from "../../components/AdminUpdateProductForm";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";

const AdminUpdateProduct = () => {
  return (
    <Fragment>
      <Navbar2 hclass={"wpo-header-style-2"} topbarBlock={"topbar-block"} />
      <PageTitle pageTitle={"Update Product"} pagesub={"Admin"} />
      <AdminUpdateProductForm />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AdminUpdateProduct;
